import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { memo, useCallback, useMemo, useState } from "react";
import uiText from "../../context/translation.json";
import {
  editableColumns,
  invoiceTypes,
  nonEditableColumns,
  specialCategoryTypes,
} from "./aadeFieldMenuOptions";
import formUiText from "./documentDetailFormTranslations.json";

const getDetailsFields = (detail, def) => {
  // don't do this detail.aade_expenses_category ?? def
  return {
    id: detail.id,
    aade_expenses_category: detail.aade_expenses_category
      ? detail.aade_expenses_category
      : def,
    aade_expenses_type: detail.aade_expenses_type
      ? detail.aade_expenses_type
      : def,
    aade_expenses_vat_type: detail.aade_expenses_vat_type
      ? detail.aade_expenses_vat_type
      : def,
    aade_income_category: detail.aade_income_category
      ? detail.aade_income_category
      : def,
    aade_income_type: detail.aade_income_type ? detail.aade_income_type : def,
    aade_vat_category: detail.aade_vat_category
      ? detail.aade_vat_category
      : def,
    aade_vat_exemption_category: detail.aade_vat_exemption_category
      ? detail.aade_vat_exemption_category
      : def,
    is_discount_option: detail.is_discount_option
      ? detail.is_discount_option
      : def,
    is_not_vat195: detail.is_not_vat195
      ? detail.is_not_vat195
      : def,
  };
};

const EditableTable = memo(
  ({ details, handleDetailChange, itIsExpense, itIsAade, uiLang }) => {
    const disabledColumns = itIsAade
      ? [
        "aade_income_category",
        "aade_income_type",
        "aade_expenses_category",
        "aade_expenses_type",
        "aade_expenses_vat_type",
        "aade_vat_category",
        "aade_vat_exemption_category",
      ]
      : itIsExpense
        ? ["aade_income_category", "aade_income_type"]
        : [
          "aade_expenses_category",
          "aade_expenses_type",
          "aade_expenses_vat_type",
        ];
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {nonEditableColumns
                .filter((col) => col.valueFromProperty !== "id")
                .map((col) => (
                  <TableCell key={`header-${col.valueFromProperty}`}>
                    {formUiText[`document_form_${col.label}`][uiLang]}
                  </TableCell>
                ))}
              {editableColumns
                .filter((col) => col.valueFromProperty !== "id")
                .map((col) => (
                  <TableCell key={`header-${col.valueFromProperty}`}>
                    {formUiText[`document_form_${col.label}`][uiLang]}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((row) => (
              <TableRow key={row.id}>
                {nonEditableColumns
                  .filter((col) => col.valueFromProperty !== "id")
                  .map((col) => (
                    <TableCell key={`${row.id}-${col.valueFromProperty}`}>
                      {row[col.valueFromProperty]}
                    </TableCell>
                  ))}
                {editableColumns
                  .filter((col) => col.valueFromProperty !== "id")
                  .map((col) => (
                    <TableCell key={`${row.id}-${col.valueFromProperty}`}>
                      <FormControl
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "140px",
                        }}
                      >
                        <Select
                          value={row[col.valueFromProperty] ?? ""}
                          onChange={(event) =>
                            handleDetailChange(
                              row.id,
                              col.valueFromProperty,
                              event.target.value
                            )
                          }
                          disabled={disabledColumns.includes(
                            col.valueFromProperty
                          )}
                          size="small"
                          width="100"
                        >
                          {col.menu.map((m) => (
                            <MenuItem
                              key={`${row.id}-${col.valueFromProperty}-${m.value}`}
                              value={m.value}
                            >
                              {m.display}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

export default function DocumentDetailsForm({
  document,
  uiLang,
  handleDocumentSave,
  handleEditFormDialogOpen,
}) {
  const [commentField, setCommentField] = useState(document.comments ?? "");
  const [invoiceType, setInvoiceType] = useState(
    document.aade_invoice_type ?? ""
  );
  const [specialInvoiceCategory, setSpecialInvoiceCategory] = useState(
    document.aade_special_invoice_category ?? ""
  );
  const [aadeClassificationPostMode, setAadeClassificationPostMode] = useState(
    document.aade_classification_post_mode ?? ""
  );
  const [isIgnored, setIsIgnored] = useState(document.is_ignored ?? false);
  const [aadeDetailFields, setAadeDetailFields] = useState(
    document.document_details.map((detail) => getDetailsFields(detail, ""))
  );

  const handleSaveClick = () => {
    handleDocumentSave({
      id: document.id,
      comments: commentField,
      aade_invoice_type: invoiceType ? invoiceType : null,
      aade_special_invoice_category: specialInvoiceCategory
        ? specialInvoiceCategory
        : null,
      aade_classification_post_mode: aadeClassificationPostMode
        ? aadeClassificationPostMode
        : null,
      is_ignored: isIgnored ? isIgnored : null,
      document_details: aadeDetailFields.map((det) =>
        getDetailsFields(det, null)
      ),
    });
  };

  const handleDetailChange = useCallback((id, field, value) => {
    setAadeDetailFields((prev) => {
      return prev.map((detail) => {
        if (detail.id === id) {
          return { ...detail, [field]: value };
        } else {
          return { ...detail };
        }
      });
    });
  }, []);

  const handleCommentFieldChange = (event) => {
    setCommentField(event.target.value);
  };

  const handleInvoiceTypeChange = (event) => {
    setInvoiceType(event.target.value);
  };
  const handleSpecialInvoiceCategoryChange = (event) => {
    setSpecialInvoiceCategory(event.target.value);
  };

  const handleAadeClassificationPostMode = (event) => {
    setAadeClassificationPostMode(event.target.value);
  };
  const handleIsIgnored = (event) => {
    setIsIgnored(event.target.checked);
  };

  const detailsForEditableTable = useMemo(() => {
    return document.document_details.map((detail) => ({
      // sacrilegious code
      ...nonEditableColumns.reduce(
        (prev, curr) => ({
          ...prev,
          ...(curr.valueFromProperty in detail && {
            [curr.valueFromProperty]: detail[curr.valueFromProperty],
          }),
        }),
        {}
      ),
      ...aadeDetailFields.filter((d) => d.id === detail.id)[0],
    }));
  }, [aadeDetailFields, document.document_details]);

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {uiText.detail_comments_document[uiLang]}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description"></DialogContentText>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <FormControl
                variant="filled"
                sx={{ minWidth: 150 }}
                disabled={document.origin_type === "AADE"}
              >
                <InputLabel id="invoice-type-label">
                  {formUiText.document_form_invoice_type[uiLang]}
                </InputLabel>
                <Select
                  labelId="invoice-type-label"
                  id="invoice-type-select"
                  value={invoiceType}
                  onChange={handleInvoiceTypeChange}
                >
                  {[...invoiceTypes, { value: "", display: "-" }].map((it) => (
                    <MenuItem key={it.value} value={it.value}>
                      {it.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="filled"
                sx={{ minWidth: 150 }}
                disabled={document.origin_type === "AADE"}
              >
                <InputLabel id="special-category-label">
                  {formUiText.document_form_special_invoice_category[uiLang]}
                </InputLabel>
                <Select
                  labelId="special-category-label"
                  id="special-category-select"
                  value={specialInvoiceCategory}
                  onChange={handleSpecialInvoiceCategoryChange}
                >
                  {[...specialCategoryTypes, { value: "", display: "-" }].map(
                    (it) => (
                      <MenuItem key={it.value} value={it.value}>
                        {uiLang === "en" ? it.display_en : it.display_el}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>


            <Grid item>
              {document.document_category === "EXPENSES" && (
                <FormControl
                  variant="filled"
                  sx={{ minWidth: 250 }}
                  disabled={document.origin_type === "AADE"}
                >
                  <InputLabel id="classification-post-mode-label">
                    {uiText.aade_classification_post_mode[uiLang]}
                  </InputLabel>
                  <Select
                    labelId="classification-post-mode-label"
                    id="classification-post-mode-select"
                    value={aadeClassificationPostMode}
                    onChange={handleAadeClassificationPostMode}
                  >
                    {[
                      { value: "", display: "-" },
                      {
                        value: "0",
                        display:
                          uiText.aade_classification_post_mode_default[uiLang],
                      },
                      {
                        value: "1",
                        display:
                          uiText.aade_classification_post_mode_alternative[
                          uiLang
                          ],
                      },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isIgnored}
                    onChange={handleIsIgnored} />}
                  label={uiText.IGNORED[uiLang]}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <br />
          <EditableTable
            details={detailsForEditableTable}
            handleDetailChange={handleDetailChange}
            itIsExpense={document.document_category === "EXPENSES"}
            itIsAade={document.origin_type === "AADE"}
            uiLang={uiLang}
          />
          <br />
          <Box display="flex" width="100%" gap={3}>
            <TextField
              id="textfield-comment-field"
              label={uiText.detail_comments[uiLang]}
              type="text"
              fullWidth
              multiline
              minRows={6}
              maxRows={6}
              variant="outlined"
              style={{
                flexGrow: 1,
                width: "300px",
                margin: "8px 0",
                fontSize: "16px",
              }}
              value={commentField}
              onChange={handleCommentFieldChange}
              error={commentField.length > 500}
              helperText={
                commentField && commentField.length > 500
                  ? `${uiText.detail_characters_limit[uiLang]} ${commentField.length - 500
                  }`
                  : `${500 - (commentField ? commentField.length : 0)} ${uiText.detail_characters_left[uiLang]
                  } `
              }
            />
            {document.origin_type !== "AADE" &&
              (invoiceType === "11.4" || invoiceType === "14.30") && (
                <TextField
                  autoFocus
                  multiline
                  minRows={6}
                  maxRows={6}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: { color: "black" },
                  }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  id="textfield-comment-info"
                  label="Α.1020/2024"
                  sx={{ flexGrow: 1, margin: "8px 0" }}
                  defaultValue={
                    invoiceType === "11.4"
                      ? uiText.aade_association_difficulty_11_4[uiLang]
                      : invoiceType === "14.30"
                        ? uiText.aade_association_difficulty_14_30[uiLang]
                        : ""
                  }
                />
              )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleEditFormDialogOpen(false);
          }}
        >
          {uiText.cancel[uiLang]}
        </Button>
        <Button
          onClick={() => {
            if (!(commentField && commentField.length > 500)) {
              handleSaveClick();
            }
          }}
          autoFocus
        >
          {uiText.save[uiLang]}
        </Button>
      </DialogActions>
    </>
  );
}
